<template>
  <div>
    <el-breadcrumb
      separator-class="el-icon-arrow-right"
      v-if="this.$router.currentRoute.path!='/home'"
    >
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>系统设置</el-breadcrumb-item>
      <el-breadcrumb-item>数据管理</el-breadcrumb-item>
      <el-breadcrumb-item>数据列表</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card
      shadow="hover"
      class="box-card"
      align="middle"
    >
      <div class="head">
        <i
          class="el-icon-s-tools"
          style="font-size: 18px;"
        ></i>
        <span class="text">设置选项</span>
      </div>
      </br></br></br>
      <el-form
        :model="dataFileSetting"
        label-width="250px"
        :rules="rules"
      >

        <el-form-item
          label="数据查询范围："
          prop="querytime"
        >

          <el-input
            class="input-width"
            v-model="dataFileSetting.querytime"
          >
            <template slot="prepend">过去</template>
            <template slot="append">min</template>
          </el-input>

        </el-form-item>
        <el-form-item
          label="单页展示数："
          prop="numberperpage"
        >
          <el-input-number
            v-model="dataFileSetting.numberperpage"
            :step="10"
            :min="10"
            :max="50"
          >
          </el-input-number>

        </el-form-item>
        <!-- <div style="float:left">
					<i class="el-icon-coin" style="font-size: 16px;"></i>
					<span style="margin-left:5px;font-family: 'Courier New', Courier, monospace;
      font-size: 13px;">数据库维护</span>
				</div> -->

        <el-form-item>
          <el-button
            class="finish"
            type="primary"
            @click="onSubmit"
          >完成</el-button>
        </el-form-item>
      </el-form>
    </el-card>

  </div>
</template>

<script>
// 验证规则，需为数字值
const checkTime = (rule, value, callback) => {
  if (!value) {
    return callback(new Error("输入不能为空"));
  }
  console.log("checkTime", value);
  let intValue = parseInt(value);
  if (!Number.isInteger(intValue)) {
    return callback(new Error("请输入数字值"));
  }
  callback();
};
export default {
  name: "set_dataList",
  data() {
    return {
      dataFileSetting: {
        name: "数据列表",
        numberperpage: 0,
        querytime: 1,
      },

      rules: {
        numberperpage: {
          validator: checkTime,
          trigger: "blur",
        },
        querytime: {
          validator: checkTime,
          trigger: "blur",
        },
        time: {
          validator: checkTime,
          trigger: "blur",
        },
      },
    };
  },
  mounted() {
    this.initData();
  },
  methods: {
    // 完成按钮
    onSubmit() {
      this.$confirm("此操作将修改设置信息, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          console.log(this.dataFileSetting);
          this.putRequest(
            "/systemSetting/updateSettingInfoByName",
            this.dataFileSetting
          ).then((resp) => {
            // this.postRequest('/systemSetting/insertSettingInfo',this.dataFileSetting).then(resp => {
            if (resp) {
              console.log("修改成功!");
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消修改",
          });
        });
    },
    initData() {
      this.getRequest(
        "/systemSetting/getSettingInfoByName/?name=" + this.dataFileSetting.name
      ).then((resp) => {
        if (resp) {
          this.dataFileSetting.querytime = resp.data.querytime;
          this.dataFileSetting.numberperpage = resp.data.numberperpage;
        }
      });
    },
  },
};
</script>


<style scoped>
.el-card{
  width: 800px!important;
}
.head{
  float: left;
}
.box-card {
  width: 580px;
  margin:40px 170px;
  border: 1px solid #d5ecf6;
  font-size: 15px!important;
}
.box-card .text{
  display: inline-block;
  margin-left: 10px;

}
.el-form-item{
  margin-bottom: 30px;
  margin-left: -50px;

}
.finish {
  margin-top: 30px;
  margin-left: 330px;
}
.el-form-item__content .el-input-group {
    vertical-align: top;
    width: 30%;
}
</style>